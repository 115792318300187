<template>
  <footer color="stylish-color-dark" class="page-footer font-small pt-4 mt-4">
    <b-container class="text-left">
      <b-row>
        <b-col md="6">
          <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">{{ $t('ems.footer.support') }}</h5>
          <ul class="list-unstyled">
            <li class="mb-4" style="white-space: pre-wrap">{{ $t('ems.footer.supprorHint') }} <b-link :href="supportMail">{{ $t('ems.footer.email') }}</b-link></li>
            <li><b-link :href="supportMail"><b-img height="40" src="https://www.archimedes-exhibitions.de/site/uploads/2018/11/amdx-logo.svg"/></b-link></li>
          </ul>
        </b-col>
        <hr class="clearfix w-100 d-md-none" />
        <b-col md="3">
          <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">{{ $t('ems.footer.ems') }}</h5>
          <ul class="list-unstyled">
            <li><b-link to="/">{{ $t('ems.footer.map') }}</b-link></li>
            <li><b-link to="/power" v-if="isPower">{{ $t('ems.nav.power') }}</b-link></li>
            <li><b-link to="/timeline" v-if="isTimeline">{{ $t('ems.nav.timeline') }}</b-link></li>
            <li><b-link to="/control" v-if="isClients">{{ $t('ems.nav.clients') }}</b-link></li>
            <li><b-link to="/cms">{{ $t('ems.footer.content') }}</b-link></li>
            <li><b-link v-if="isDocs" :href="baseURL + 'docs/' + currentlocale + '/latest/index.html'" target="_blank">{{ $t('ems.footer.docs') }}</b-link></li>
            <li><b-link to="/drivers" v-if="isDrivers">{{ $t('ems.nav.drivers') }}</b-link></li>
          </ul>
        </b-col>
        <hr class="clearfix w-100 d-md-none" />
        <b-col md="3" v-if="isDocs">
          <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">Docs</h5>
          <ul class="list-unstyled">
            <li><b-link :href="baseURL + 'docs/' + currentlocale + '/latest/power.html'" target="_blank">{{ $t('ems.nav.power') }}</b-link></li>
            <li><b-link :href="baseURL + 'docs/' + currentlocale + '/latest/schedule.html'" target="_blank">{{ $t('ems.nav.timeline') }}</b-link></li>
            <li><b-link :href="baseURL + 'docs/' + currentlocale + '/latest/clients.html'" target="_blank">{{ $t('ems.nav.clients') }}</b-link></li>
            <li><b-link :href="baseURL + 'docs/' + currentlocale + '/latest/cms.html'" target="_blank">{{ $t('ems.footer.content') }}</b-link></li>
            <li><b-link :href="baseURL + 'docs/' + currentlocale + '/latest/support.html'" target="_blank">{{ $t('ems.footer.serviceSupport') }}</b-link></li>
          </ul>
        </b-col>
        <hr class="clearfix w-100 d-md-none" />
      </b-row>
    </b-container>
    <div class="text-center">
      <ul class="list-unstyled list-inline">
        <li class="list-inline-item"><a class="btn-floating btn-sm btn-fb mx-1"><i class="fab fa-facebook"> </i></a></li>
        <li class="list-inline-item"><a class="btn-floating btn-sm btn-tw mx-1"><i class="fab fa-twitter"> </i></a></li>
        <li class="list-inline-item"><a class="btn-floating btn-sm btn-gplus mx-1"><i class="fab fa-google-plus"> </i></a></li>
        <li class="list-inline-item"><a class="btn-floating btn-sm btn-li mx-1"><i class="fab fa-linkedin-in"> </i></a></li>
        <li class="list-inline-item"><a class="btn-floating btn-sm btn-dribbble mx-1"><i class="fab fa-dribbble"> </i></a></li>
      </ul>
    </div>
    <div class="footer-copyright text-center py-3">
      <b-container fluid>
        &copy; 2020 Copyright: <b-link href="https://www.archimedes-exhibitions.de"> Archimedes-Exhibitions GmbH </b-link> | v{{ $VUE_APP_VERSION }}
      </b-container>
    </div>
  </footer>
</template>

<script>
  import Vue from 'vue'

  export default {
    name: 'Footer',
    data () {
      return {
      }
    },
    computed: {
      supportMail () {
        return 'mailto:' + Vue.prototype.$SUPPORT_EMAIL
      },
      currentlocale () {
        return this.$root.$i18n.locale
      },
      baseURL () {
        return process.env.BASE_URL
      },
      isPower () {
        return Vue.prototype.$IS_POWER
      },
      isTimeline () {
        return Vue.prototype.$IS_TIMELINE
      },
      isClients () {
        return Vue.prototype.$IS_CLIENTS
      },
      isDocs () {
        return Vue.prototype.$IS_DOCS
      },
      isDrivers () {
        return Vue.prototype.$IS_DRIVERS
      }
    }
  }
</script>
<style lang="scss" scoped>
  footer {
    background-color: #f2f2f2;
    border-top: 3px solid #dee2e6;
    text-align: center;
  }

</style>
