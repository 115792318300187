/*
 * Copyright (C) 2019. Archimedes Exhibitions GmbH,
 * Saarbrücker Str. 24, Berlin, Germany
 *
 * This file contains proprietary source code and confidential
 * information. Its contents may not be disclosed or distributed to
 * third parties unless prior specific permission by Archimedes
 * Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
 * to copies made in any form and using any medium. It applies to
 * partial as well as complete copies.
 */

import Vue from 'vue'

import anyPb from 'google-protobuf/google/protobuf/any_pb.js'
import timestampPb from 'google-protobuf/google/protobuf/timestamp_pb.js'

const MAX_ELEM_COUNT = 2000000000

const HEALTH_STATUS_SERVING = 'HEALTH_STATUS_SERVING'
const HEALTH_STATUS_NOT_SERVING = 'HEALTH_STATUS_NOT_SERVING'
const HEALTH_STATUS_SERVICE_UNKNOWN = 'HEALTH_STATUS_SERVICE_UNKNOWN'

export default class DriverManager extends Vue {
  constructor (brokerAddress, brokerGrpcWebPb, timeout) {
    super()

    this._drivers = null
    this._clients = null
    this._topics = []
    this._topicStream = null

    this._timeout = timeout
    this.brokerGrpcWebPb = brokerGrpcWebPb
    this.broker = new brokerGrpcWebPb.BrokerClient(
      brokerAddress
    )

    Vue.component('driver-action-ui', {
      render (createElement) {
        let ui = this.driver.UI
        let that = this
        return createElement(
          ui, {
            attrs: {
              driver: this.driver,
              client: this.client,
              parameterName: this.parameterName
            },
            on: {
              valuePicked (value) {
                that.$emit('valuePicked', value)
              }
            }
          }
        )
      },
      props: {
        driver: {
          type: Object,
          required: true
        },
        client: {
          type: Object,
          required: true
        },
        parameterName: {
          type: String
        }
      }
    })

    this._isClean = false
    this._errorTimer = null
    window.addEventListener('beforeunload', this.cleanUp)
  }

  get HEALTH_STATUS_SERVING () {
    return HEALTH_STATUS_SERVING
  }

  get HEALTH_STATUS_NOT_SERVING () {
    return HEALTH_STATUS_NOT_SERVING
  }

  get HEALTH_STATUS_SERVICE_UNKNOWN () {
    return HEALTH_STATUS_SERVICE_UNKNOWN
  }

  get drivers () {
    return this._drivers
  }

  get clients () {
    return this._clients
  }

  get topics () {
    return this._topics
  }

  driverIsImplemented (driverName) {
    return Vue[driverName] !== undefined
  }

  updateBrokerData () {
    return this.updateDrivers()
      .then(() => {
        return this.updateClients()
          .then(() => {
            this._clients = this._clients.filter(c => {
              let d = this._drivers.find(d => d.driverId === c.driverId)
              return !!d
            })
          })
      })
      .then(() => {
        return this.updateTopics()
      })
  }

  updateDrivers (nextPageToken = null) {
    if (nextPageToken == null) {
      this._drivers = []
    }
    return this.listDrivers(nextPageToken)
      .then(data => {
        this._drivers = this._drivers.concat(data.driversList)
        if (data.nextPageToken) {
          return this.updateDrivers(data.nextPageToken)
        }
      })
  }

  updateClients (nextPageToken = null) {
    if (nextPageToken == null) {
      this._clients = []
    }
    return this.listClients(nextPageToken)
      .then(data => {
        this._clients = this._clients.concat(data.clientsList)
        if (data.nextPageToken) {
          return this.updateClients(data.nextPageToken)
        }
      })
  }

  updateTopics (nextPageToken = null, topics = null) {
    if (nextPageToken == null) {
      topics = []
    }
    return this.listTopics(nextPageToken)
      .then(data => {
        topics = topics.concat(data.topicsList)
        if (data.nextPageToken) {
          return this.updateTopics(data.nextPageToken, topics)
        } else {
          if (this._topicStream) {
            let newTopics = topics.filter(t => !this._topics.includes(t))
            let oldTopics = this._topics.filter(t => !topics.includes(t))
            if (newTopics.length || oldTopics.length) {
              this._topicStream.cancel()
              this._topicStream = this._getSubscriberStream(topics)
              this._topicStream.on(
                'data',
                (response, that = this) => this._onTopicUpdate(response, that)
              )
              this._topicStream.on('error', this._onTopicError)
            }
          } else {
            this._topicStream = this._getSubscriberStream(topics)
            this._topicStream.on(
              'data',
              (response, that = this) => this._onTopicUpdate(response, that)
            )
            this._topicStream.on('error', this._onTopicError)
          }
          this._topics = topics
        }
      })
  }

  listDrivers (nextPageToken = null, pageSize = MAX_ELEM_COUNT) {
    let request = new this.brokerGrpcWebPb.ListDriversRequest()
    request.setPageSize(pageSize)
    if (nextPageToken) {
      request.setPageToken(nextPageToken)
    }
    return new Promise((resolve, reject) => {
      this.broker.listDrivers(
        request,
        this._getMetaData(),
        (err, response) => {
          if (err == null) {
            response = response.toObject()
            let drivers = []
            for (let i = 0; i < response.driversList.length; i++) {
              let protoDriver = response.driversList[i]
              if (this.driverIsImplemented(protoDriver.name)) {
                let aliases = Vue[protoDriver.name].aliases
                if (aliases.length) {
                  for (let alias of aliases) {
                    let uiDriver = new Vue[protoDriver.name]()
                    uiDriver.alias = alias
                    drivers.push(Object.assign(uiDriver, protoDriver))
                  }
                } else {
                  let uiDriver = new Vue[protoDriver.name]()
                  uiDriver.alias = protoDriver.name
                  drivers.push(Object.assign(uiDriver, protoDriver))
                }
              } else {
                console.info(protoDriver.name + ' is not implemented.')
              }
            }
            response.driversList = drivers
            resolve(response)
          } else {
            reject(new Error(JSON.stringify(err)))
          }
        })
    })
  }

  listClients (nextPageToken = null, pageSize = MAX_ELEM_COUNT) {
    let request = new this.brokerGrpcWebPb.ListClientsRequest()
    request.setPageSize(pageSize)
    if (nextPageToken) {
      request.setPageToken(nextPageToken)
    }
    return new Promise((resolve, reject) => {
      this.broker.listClients(
        request,
        this._getMetaData(),
        (err, response) => {
          if (err == null) {
            resolve(response.toObject())
          } else {
            reject(new Error(JSON.stringify(err)))
          }
        })
    })
  }

  createClient (name, driver, parameters) {
    // Make request
    let factoryRequest = driver.factoryRequest
    let paramSetter = driver.factoryParameter

    // Prepare parameters
    for (let key in parameters) {
      if (!(key in paramSetter)) {
        throw Error('Parameter ' + key + 'is not implemented!')
      }
      let value = parameters[key]
      if (typeof value === 'string') {
        value = value.trim()
      }
      let methodName = paramSetter[key][0]
      let converter = paramSetter[key][1]
      if (converter) {
        value = converter(value)
        if (Number.isNaN(value)) {
          let err = 'Value "' + key + '" has the wrong type!'
          return new Promise((resolve, reject) => {
            reject(new Error(err))
          })
        }
      }
      factoryRequest[methodName](value)
    }

    let client = new this.brokerGrpcWebPb.Client()
    client.setClientName(name)
    client.setDriverId(driver.driverId)
    // Make envelope
    let any = new anyPb.Any()

    let typePath = null
    for (let entry of driver.factoriesList) {
      if (entry.method === '__init__') {
        typePath = entry.request
      }
    }

    if (typePath === null) {
      let err = '__init__ repsponse path could not be found!'
      return new Promise((resolve, reject) => {
        reject(new Error(err))
      })
    }

    any.pack(factoryRequest.serializeBinary(), typePath)
    // Set envelope in request
    client.setFactoryRequest(any)

    // Send request
    return new Promise((resolve, reject) => {
      this.broker.createClient(
        client,
        this._getMetaData(),
        this._handleCallback(reject, resolve)
      )
    })
  }

  deleteClient (clientId) {
    let request = new this.brokerGrpcWebPb.DeleteClientRequest()
    request.setClientId(clientId)
    return new Promise((resolve, reject) => {
      this.broker.deleteClient(
        request,
        this._getMetaData(),
        this._handleCallback(reject, resolve)
      )
    })
  }

  listEvents (nextPageToken = null, pageSize = MAX_ELEM_COUNT) {
    let request = new this.brokerGrpcWebPb.ListEventsRequest()
    request.setPageSize(pageSize)
    if (nextPageToken) {
      request.setPageToken(nextPageToken)
    }
    return new Promise((resolve, reject) => {
      this.broker.listEvents(
        request,
        this._getMetaData(),
        (err, response) => {
          if (err == null) {
            resolve(response.toObject())
          } else {
            reject(new Error(JSON.stringify(err)))
          }
        })
    })
  }

  createEvent (time, methodName, client, driver, parameters, rruleString = null) {
    let ts = new timestampPb.Timestamp()
    let seconds = parseInt(time)
    let nanos = parseInt((parseFloat(time) - seconds) * 1000000000)
    ts.setSeconds(seconds)
    ts.setNanos(nanos)

    let event = new this.brokerGrpcWebPb.Event()
    event.setTimestamp(ts)

    if (rruleString) {
      event.setRrulestr(rruleString)
    }

    let rpcRequestData = this.createActionRequest(
      methodName, client, driver, parameters
    )
    if (rpcRequestData.error != null) {
      return new Promise((resolve, reject) => {
        reject(new Error(JSON.stringify(rpcRequestData.error)))
      })
    }

    event.setRpcRequest(rpcRequestData.request)

    return new Promise((resolve, reject) => {
      this.broker.createEvent(
        event,
        this._getMetaData(),
        this._handleCallback(reject, resolve)
      )
    })
  }

  deleteEvent (eventId) {
    let request = new this.brokerGrpcWebPb.DeleteEventRequest()
    request.setEventId(eventId)
    return new Promise((resolve, reject) => {
      this.broker.deleteEvent(
        request,
        this._getMetaData(),
        this._handleCallback(reject, resolve)
      )
    })
  }

  createActionRequest (methodName, client, driver, parameters) {
    let actionHandler = driver.getEventHandler(methodName)
    let parameterHandler = driver.getEventParameterHandler(methodName)

    let actionRequest = actionHandler.request

    let methodPath = null

    for (let entry of driver.signaturesList) {
      if (entry.method === methodName) {
        methodPath = entry.request
      }
    }

    if (methodPath == null) {
      let err = 'Path for ' + methodName + ' method could not be found!'
      return { request: null, actionHandler: null, error: err }
    }

    if (parameters != null) {
      for (let key in parameters) {
        let value = parameters[key]
        if (typeof value === 'string') {
          value = value.trim()
        }
        let methodName = null
        let converter = null
        for (let p of parameterHandler) {
          if (p.name === key) {
            methodName = p.handler[0]
            converter = p.handler[1]
          }
        }

        if (methodName == null) {
          throw Error('Parameter ' + key + 'is not implemented!')
        }

        if (converter) {
          value = converter(value)
          if (Number.isNaN(value)) {
            let err = 'Value "' + key + '" has the wrong type!'
            return { request: null, actionHandler: null, error: err }
          }
        }
        actionRequest[methodName](value)
      }
    }

    let envelope = new anyPb.Any()
    envelope.pack(actionRequest.serializeBinary(), methodPath)

    let request = new this.brokerGrpcWebPb.RpcRequest()
    request.setClientId(client.clientId)
    request.setMethod(methodName)
    request.setRequest(envelope)

    let isRetained = false
    if (actionHandler.isRetained !== undefined) {
      isRetained = actionHandler.isRetained
    }
    request.setRetained(isRetained)

    return { request: request, actionHandler: actionHandler, error: null }
  }

  listTopics (nextPageToken = null, pageSize = MAX_ELEM_COUNT) {
    let request = new this.brokerGrpcWebPb.ListTopicsRequest()
    request.setPageSize(pageSize)
    if (nextPageToken) {
      request.setPageToken(nextPageToken)
    }
    return new Promise((resolve, reject) => {
      this.broker.listTopics(
        request,
        this._getMetaData(),
        (err, response) => {
          if (err == null) {
            resolve(response.toObject())
          } else {
            reject(new Error(JSON.stringify(err)))
          }
        })
    })
  }

  sendAction (methodName, client, driver, parameters) {
    let requestData = this.createActionRequest(
      methodName, client, driver, parameters)
    if (requestData.error != null) {
      return new Promise((resolve, reject) => {
        reject(new Error(JSON.stringify(requestData.error)))
      })
    }

    let request = requestData.request
    let deserializer = requestData.actionHandler.deserializer

    return new Promise((resolve, reject) => {
      this.broker.dispatchRpc(
        request,
        this._getMetaData(),
        (err, response) => {
          if (err != null) {
            reject(new Error(JSON.stringify(err)))
          } else {
            // Check if response has an envelope
            if (!response.hasResponse()) {
              reject(new Error(JSON.stringify(response.getError())))
            } else {
              // Get envelope
              let envelope = response.getResponse()
              if (!envelope.toObject().value) {
                resolve(null)
              } else {
                // Unpack envelope with the specific deserializer
                let message = envelope.unpack(
                  deserializer, envelope.getTypeName())
                resolve(message.toObject())
              }
            }
          }
        })
    })
  }

  deserializeRpcRequest (event, clients, drivers) {
    if (event.rpcRequest.request === undefined) {
      return
    }
    let rpcRequest = event.rpcRequest
    let client = clients.find((obj) => {
      return obj.clientId === rpcRequest.clientId
    })
    if (client == null) {
      throw new Error(
        'Client ' + rpcRequest.clientId + ' could not be found ' +
        'in rpc deserialization of event ' + event.eventId + '!'
      )
    }
    let driver = drivers.find((obj) => {
      return obj.driverId === client.driverId
    })
    if (driver == null) {
      throw new Error(
        'Driver ' + client.driverId + ' could not be found ' +
        'in rpc deserialization of event ' + event.eventId + '!'
      )
    }
    let deserializer = driver.getRequestDeserializer(rpcRequest.method)
    event.rpcRequest.request = deserializer(rpcRequest.request.value).toObject()
  }

  computeDriverAlias (client, driver) {
    if (driver.constructor.aliases.length) {
      return this.sendAction(driver.aliasComputeMethod, client, driver)
        .then(data => {
          return driver.computeAlias(data)
        })
    }
    return new Promise(resolve => {
      resolve(null)
    })
  }

  cleanUp () {
    this._isClean = true
    if (this._topicStream) {
      this._topicStream.cancel()
      this._topicStream = null
    }
  }

  _getSubscriberStream (topics) {
    let request = new this.brokerGrpcWebPb.SubscriptionRequest()
    request.setTopicsList(topics)
    let stream = this.broker.subscribe(
      request,
      this._getMetaData(false)
    )
    return stream
  }

  _handleCallback (reject, resolve) {
    return (err, response) => {
      if (err != null) {
        reject(new Error(JSON.stringify(err)))
      } else {
        resolve(response.toObject())
      }
    }
  }

  _getTimeout () {
    let deadline = new Date()
    deadline.setSeconds(deadline.getSeconds() + this._timeout)
    return deadline.getTime()
  }

  _getMetaData (isDeadline = true) {
    let data = {}
    if (isDeadline) {
      data.deadline = this._getTimeout()
    }
    if (Vue.prototype.$keycloakmanager.isConfigured) {
      data.Authorization = 'Bearer ' + Vue.prototype.$keycloakmanager.token
    }
    return data
  }

  _onTopicUpdate (response, that) {
    let data = response.toObject()
    let driverId = data.topic.split('/')[0]
    let clientId = data.topic.split('/')[1]
    let driver = that._drivers.find(d => d.driverId === driverId)
    if (driver) {
      let status = driver.convertStatus(data, false)
      that.$emit('client-update', { 'clientId': clientId, 'status': status })
    }
  }

  _onTopicError (error) {
    console.error('Topic error:', error)
    if ((error.code === 2 || error.code === 14) && !this._isClean && !this._errorTimer) {
      this._errorTimer = setTimeout(
        () => {
          if (this._isClean) {
            return
          }
          this._errorTimer = null
          this._topicStream.cancel()
          this._topicStream = null
          this.updateTopics()
        },
        Math.floor(Math.random() * 5000)
      )
    }
  }
}
